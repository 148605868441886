import React, { useCallback } from 'react';
import SimpleModal from '../../../SimpleModal';
import JSONEditor from '../../../JSONEditor';
import { getSiteSchema, siteUISchema } from './schemas/siteSchema';
import { mailRegex, phoneRegex } from '../../../../utils';

const SiteCreateModal = ({ siteParams, layouts = [], onSubmit, onCloseModal, transformErrors }) => {
  const schema = getSiteSchema(layouts);
  const createFields =
    siteParams && Object.keys(siteParams).length > 0
      ? {
          name: siteParams.name,
          customer: siteParams.customer,
          org: siteParams.org,
          location: {
            city: siteParams.city,
            country: siteParams.country,
            lat: parseInt(siteParams.lat, 10),
            lng: parseInt(siteParams.lng, 10),
          },
        }
      : {};
  const customValidate = useCallback(
    (formData, errors) => {
      if (formData.support.email && !mailRegex.test(formData.support.email)) {
        errors.support.email.addError('Enter valid email');
      }
      if (formData.support.phone && !phoneRegex.test(formData.support.phone)) {
        errors.support.phone.addError(
          'Phone number must start with a plus sign (+) and country code.'
        );
      }
      return errors;
    },
    [schema]
  );
  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Add site"
    >
      <div className="create-site-content">
        <JSONEditor
          title="Site"
          formData={createFields}
          showErrorList={false}
          schema={schema}
          uiSchema={siteUISchema}
          initialEditMode={true}
          showEditButton={false}
          saveButtonText={'Create'}
          cancelCallback={onCloseModal}
          onFormSubmit={({ formData }) => onSubmit(formData)}
          customValidate={customValidate}
          customTransformErrors={(errors) => transformErrors(errors)}
        />
      </div>
    </SimpleModal>
  );
};

export default SiteCreateModal;
