/* eslint-disable default-param-last */
import { call, take, takeEvery, takeLatest, delay, put, race, select } from 'redux-saga/effects';

import { checkNetworkStatus } from '../utils';
import { REFRESH_VALUES, CLEAR_SITE_DATA } from './application';

let lastNetworkCheck = 0;

const crypto = window.crypto || window.msCrypto;
const array = new Uint32Array(1);

export const getTempId = () => Date.now() + crypto.getRandomValues(array)[0] * 1000;

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const DISPLAY_NOTIFICATION = 'dt/notification/DISPLAY_NOTIFICATION';
export const RECEIVE_NOTIFICATION = 'dt/notification/RECEIVE_NOTIFICATION';
export const HIDE_NOTIFICATION = 'dt/notification/HIDE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'dt/notification/REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATIONS_BY_TYPE = 'dt/notification/REMOVE_NOTIFICATIONS_BY_TYPE';
export const MARK_NOTIFICATIONS_READ = 'dt/notification/MARK_NOTIFICATIONS_READ';
export const START_NOTIFICATION_TIMER = 'dt/notification/START_NOTIFICATION_TIMER';
export const CANCEL_NOTIFICATION_TIMER = 'dt/notification/CANCEL_NOTIFICATION_TIMER';
export const START_POLLING_ONLINE = 'dt/notification/START_POLLING_ONLINE';
export const STOP_POLLING_ONLINE = 'dt/notification/STOP_POLLING_ONLINE';
export const SET_ONLINE = 'dt/notification/SET_ONLINE';
export const SET_OFFLINE = 'dt/notification/SET_OFFLINE';
export const CHECK_ONLINE = 'dt/notification/CHECK_ONLINE';

export const TYPES = {
  transient: 0,
  persistent: 1,
  actionable: 2,
};

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export function displayNotification(notification) {
  const createdAt = Date.now();
  const { id, type } = notification;

  return {
    type: RECEIVE_NOTIFICATION,
    notification: {
      ...notification,
      id: id || getTempId(),
      visible: true,
      type: TYPES[type] || TYPES.transient,
      createdAt,
    },
  };
}

export function hideNotification(id) {
  return {
    type: HIDE_NOTIFICATION,
    id,
  };
}

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  };
}

export function startNotificationTimer(id) {
  return {
    type: START_NOTIFICATION_TIMER,
    id,
  };
}

export function cancelNotificationTimer() {
  return {
    type: CANCEL_NOTIFICATION_TIMER,
  };
}

export function removeNotificationsByType(notificationType) {
  return {
    type: REMOVE_NOTIFICATIONS_BY_TYPE,
    notificationType,
  };
}

export function markNotificationsRead() {
  return {
    type: MARK_NOTIFICATIONS_READ,
  };
}

export function startPollingOnline() {
  return {
    type: START_POLLING_ONLINE,
  };
}

export function stopPollingOnline() {
  return {
    type: STOP_POLLING_ONLINE,
  };
}

export function setOnline() {
  return {
    type: SET_ONLINE,
  };
}

export function setOffline() {
  return {
    type: SET_OFFLINE,
  };
}

export function checkIsOnline() {
  return {
    type: CHECK_ONLINE,
  };
}

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  online: true,
  notifications: {}, // see './notification-defaults'
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION: {
      const { notification } = action;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notification.id]: notification,
        },
      };
    }
    case HIDE_NOTIFICATION: {
      const { id } = action;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [id]: {
            ...state.notifications[id],
            visible: false,
          },
        },
      };
    }
    case REMOVE_NOTIFICATION: {
      const { id } = action;
      const notifications = { ...state.notifications };
      delete notifications[id];

      return {
        ...state,
        notifications,
      };
    }
    case REMOVE_NOTIFICATIONS_BY_TYPE: {
      const { notificationType } = action;
      const updatedNotifications = Object.values(state.notifications).reduce((acc, n) => {
        if (n.type !== notificationType) {
          acc[n.id] = n;
        }
        return acc;
      }, {});

      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
    case MARK_NOTIFICATIONS_READ: {
      const updatedNotifications = Object.values(state.notifications).reduce(
        (acc, n) => ({
          ...acc,
          [n.id]: { ...n, read: true },
        }),
        {}
      );

      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
    case SET_ONLINE: {
      return {
        ...state,
        online: true,
      };
    }
    case SET_OFFLINE: {
      return {
        ...state,
        online: false,
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        online: true,
        notifications: {},
      };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getOnline = (state) => state.notifications.online;

export const getNotificationById = (state, id) => state.notifications.notifications[id];

export const getNotifications = (state) =>
  Object.values(state.notifications.notifications).sort((a, b) => a.createdAt - b.createdAt);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* delayedHideOrRemove(id, hideTimeout = 2000) {
  yield delay(hideTimeout);
  const notification = yield select(getNotificationById, id);
  if (notification && notification.type === TYPES.transient) {
    yield put(removeNotification(id));
  } else {
    yield put(hideNotification(id));
  }
}

function* doStartNotificationTimer({ id }) {
  yield race([call(delayedHideOrRemove, id), take(CANCEL_NOTIFICATION_TIMER)]);
}

function* pollReconnect() {
  while (true) {
    const online = yield call(checkNetworkStatus);
    if (online) {
      yield put(setOnline());
      yield put({ type: REFRESH_VALUES });
      yield put(stopPollingOnline());
    }
    yield delay(5000);
  }
}

function* watchStartPollingOnline() {
  while (true) {
    yield take(START_POLLING_ONLINE);
    yield race([call(pollReconnect), take(STOP_POLLING_ONLINE)]);
  }
}

export function* checkOnline() {
  const isOnlineState = yield select(getOnline);
  if (isOnlineState && lastNetworkCheck + 5000 < Date.now()) {
    lastNetworkCheck = Date.now();
    const online = yield call(checkNetworkStatus);
    if (!online) {
      yield put(setOffline());
      yield put(startPollingOnline());
    }
  }
}

export const sagas = [
  call(watchStartPollingOnline),
  takeEvery(START_NOTIFICATION_TIMER, doStartNotificationTimer),
  takeLatest(CHECK_ONLINE, checkOnline),
];
