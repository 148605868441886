export const MODE_SUMMARY = 'mode/summary';
export const MODE_MAINTENANCE = 'mode/maintenance';
export const MODE_DOCUMENTATION = 'mode/documentation';

export const NO_SITES_MESSAGE = 'No sites to display';

// eslint-disable-next-line max-len
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic3RyYW5nZSIsImEiOiJjajdxN2RxbGozbTdtMzRxcG9zcmU2amVvIn0.h8oF47rPw_qaPE40BdSVag';

/*
 * @react-components colors
 * $StatusBlue: [51, 102, 255]
 * $StatusGreen: [12, 169, 25]
 * $StatusOrange: [255, 115, 0]
 * $StatusRed: [240, 48, 64]
 */

export const STATUSES = {
  default: {
    icon: 'empty',
    color: [51, 102, 255],
    colorHex: 0xff7300,
  },
  0: {
    icon: 'he-statushealthy',
    color: [12, 169, 25],
    colorHex: 0x18bc19,
  },
  1: {
    icon: 'he-statuswarning',
    color: [255, 115, 0],
    colorHex: 0xff7300,
  },
  2: {
    icon: 'he-statusriskhigh',
    color: [240, 48, 64],
    colorHex: 0xf03040,
  },
  r1: {
    icon: 'he-statusrisklow',
    text: 'Low Risk',
    color: [45, 118, 206],
    colorHex: 0x414141,
  },
  r2: {
    icon: 'he-statusriskmedium',
    text: 'Medium Risk',
    color: [198, 182, 40],
    colorHex: 0xc6b628,
  },
  r3: {
    icon: 'he-statusriskhigh',
    text: 'High Risk',
    color: [198, 40, 40],
    colorHex: 0xc62828,
  },
};

export const SEVERITIES = {
  critical: 'Emergency',
  warning: 'Warning / Severe',
  minor: 'Minor',
  info: 'Normal',
  internal: 'Maintenance / Internal',
};

export const THRESHOLD_TYPES = {
  linear: {
    key: 'linearItems',
    name: 'Linear',
    value: 'linear',
  },
  area: {
    key: 'areaItems',
    name: 'Area',
    value: 'area',
  },
};

export const PAGE_TYPES = {
  stateset: 'Stateset',
  risk: 'Risk',
  health: 'Health',
  none: 'None',
};
export const DEFAULT_PAGETYPE = 'none';

export const DEFAULT_AGGREGATION_OPTIONS = [
  {
    label: 'Avg',
    value: 'avg',
  },
  {
    label: 'Min',
    value: 'min',
  },
  {
    label: 'Max',
    value: 'max',
  },
  {
    label: 'First',
    value: 'first',
  },
  {
    label: 'Last',
    value: 'last',
  },
];

export const GRANULARITY_OPTIONS = [
  {
    label: 'Second',
    value: '1s',
  },
  {
    label: 'Minute',
    value: '60s',
  },
  {
    label: 'Fifteen minutes',
    value: '900s',
  },
  {
    label: 'Thirty minutes',
    value: '1800s',
  },
  {
    label: 'Hour',
    value: '3600s',
  },
  {
    label: 'Day',
    value: '86400s',
  },
  {
    label: 'Week',
    value: '604800s',
  },
  {
    label: 'Month',
    value: '2592000s',
  },
  {
    label: 'Quarter',
    value: '7862400s',
  },
  {
    label: 'Year',
    value: '31536000s',
  },
];

export const EXPORT_TIMESERIES_PROGRESS_LOGIC_EC = {
  // This is the progress prediction logic for EC adapter
  // noOfDatapoints: {noOfSignals: [ProgressIncrement,timeInterval]}
  10000: {
    10: [6, 300],
    50: [5, 500],
    100: [4, 800],
    150: [3, 1100],
    200: [3, 1400],
    default: [6, 1000],
  },
  50000: {
    10: [3, 300],
    50: [2, 500],
    100: [2, 800],
    150: [2, 1100],
    200: [2, 1400],
    default: [3, 1000],
  },
  100000: {
    10: [3, 300],
    50: [2, 500],
    100: [2, 800],
    150: [2, 1100],
    200: [2, 1400],
    default: [3, 1000],
  },
};

export const EXPORT_TIMESERIES_DATA_POINTS_LIMIT_EC = 10000;
export const EXPORT_TIMESERIES_PANEL = 'timeseriesExportPanel';
export const SIGNAL_VIEWER_DATETIME = 'signalviewerdatetime';

export const EXPORT_EVENT_PANEL = 'eventExportPanel';
export const EXPORT_EVENT_LIMIT = 300000;

export const EVENT_TYPE_USER = 'user';
export const EVENT_TYPE_IOT = 'iot';
export const EVENT_TYPE_ALL = 'all';

export const USER_EVENTS = [
  {
    id: 'pending',
    type: 'Pending',
    color: '#B3B3B3',
    border: '#666666',
  },
  {
    id: 'todo',
    type: 'To-Do',
    color: '#EDE27F',
    border: '#BEB34F',
  },
  {
    id: 'open',
    type: 'Open',
    color: '#63C0E5',
    border: '#357D9C',
  },
  {
    id: 'overdue',
    type: 'Overdue',
    color: '#ec575b',
    border: '#980C21',
  },
  {
    id: 'closed',
    type: 'Closed',
    color: '#2F9C2D',
    border: '#196E17',
  },
  {
    id: 'stateless',
    type: 'N/A',
    color: '#FFFFFF',
    border: '#666666',
  },
];

export const EVENT_DEFAULT_MARGIN = {
  top: 25,
  right: 0,
  bottom: 25,
  left: 70,
};

export const FORM_STATES = {
  view: 'view',
  edit: 'edit',
  editLimited: 'edit-limited',
  create: 'create',
};

export const LIMITED_PERMISSION_ENTITIES = { events: ['Write'] }; // Permission types will be changed to array when we redesign UI to support multiple limited permissions

export const LIMITED_PERMISSIONS = Object.entries(LIMITED_PERMISSION_ENTITIES).reduce(
  (permissionList, [entity, limitablePermissions]) => {
    limitablePermissions.forEach((permission) => permissionList.push(`${entity}/${permission}`));
    return permissionList;
  },
  []
);

export const USER_EVENT_STATUSES = ['All', 'Pending', 'Open', 'Closed', 'Overdue', 'ToDo'];

export const USER_EVENT_ACTIVITY = ['All', 'Planned', 'Reactive'];

export const ENITIY_ACTION_MAP = { 0: 'Limited', 1: 'Read', 2: 'Write', 3: 'Delete' };

export const STATELESS_EVENTS = ['system-trip', 'unplanned-outage', 'general'];

export const IOT_EVENT_SOURCE_TYPES = ['elasticsearch'];

export const INTERNAL_DATA_SOURCE = 'identiq';
