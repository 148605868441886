import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button, Icon, Spinner } from '@iq/react-components';

import {
  downloadComponentsExcel,
  setImportErrors,
  getImportErrors,
  getComponentsImportedAt,
  resetImportedComponentsAt,
  upsertComponents,
  getComponents,
} from '../../../../../../../bundles/components';
import { requestModels, getAllModels } from '../../../../../../../bundles/models';
import {
  createGeometriesAndMappings,
  getMappingsImported,
  resetImportedMappingsAt,
  getImportErrors as getMappingsImportErrors,
  setImportErrors as setMappingsImportErrors,
} from '../../../../../../../bundles/geometry-mappings';
import { parseAutomationExcel } from '../../../../../../../externalUtils';
import Heading from '../../../../../../Heading';
import ConfirmationDialog from '../../../../../../ConfirmationDialog';
import InstructionalModal from '../../../../../../InstructionalModal';
import { displayNotification } from '../../../../../../../bundles/notifications';
import getNotification from '../../../../../../../bundles/notification-defaults';

const ImportStep = ({ site, onNext }) => {
  const dispatch = useDispatch();
  const currentComponents = useSelector(getComponents);
  const compsImported = useSelector(getComponentsImportedAt);
  const mappingsImported = useSelector(getMappingsImported);
  const importErrors = useSelector(getImportErrors);
  const mappingImportErrors = useSelector(getMappingsImportErrors);
  const currentSiteModels = useSelector(getAllModels);
  const fullScreenRef = useRef(null);

  const [importing, setImporting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);

  const addModelToComponents = (components, documents) => {
    const updatedComponents = [...components];

    documents.forEach((document) => {
      const componentIndex = components.findIndex(
        (c) => c.itemDesignation === document.itemDesignation
      );
      if (componentIndex !== -1) {
        updatedComponents[componentIndex] = {
          ...updatedComponents[componentIndex],
          models: [{ modelId: document.modelId, customName: document.displayName }],
        };
      }
    });

    return updatedComponents;
  };

  async function uploadDataFromExcel(file) {
    const { org, id: siteId } = site;

    const drawings = Object.values(currentSiteModels).map((model) => {
      if (model.versions.length === 0) {
        return {
          modelId: model.id,
          versionId: '',
          originalName: '',
          displayName: model.name,
        };
      }
      return {
        modelId: model.id,
        versionId: model.versions[model.versions.length - 1].id,
        originalName: model.versions[model.versions.length - 1].originalName,
        displayName: model.name,
      };
    });

    try {
      const [parsedComponentsWithAttributes, documentsWithShapes, parsingErrors] =
        await parseAutomationExcel({
          file,
          drawings,
          currentComponents,
        });

      if (parsingErrors.length) {
        dispatch(setImportErrors(parsingErrors));
      } else {
        const documentsWithShapesWithModels = addModelToComponents(
          parsedComponentsWithAttributes,
          documentsWithShapes
        );

        dispatch(upsertComponents(siteId, org, documentsWithShapesWithModels));

        dispatch(createGeometriesAndMappings(siteId, documentsWithShapes));
      }
    } catch (e) {
      dispatch(displayNotification(getNotification('uploadCompExcel', 'error')()));
    }
  }

  const onDropAccepted = ([file]) => {
    setImporting(true);
    dispatch(requestModels(site.id));
    uploadDataFromExcel(file);
  };

  const onDropRejected = () => {
    dispatch(setImportErrors(['Imports should be from a single Excel file.']));
  };

  const { getInputProps, getRootProps } = useDropzone({
    disabled: importing,
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropAccepted,
    onDropRejected,
  });

  useEffect(() => {
    if (importing && compsImported && mappingsImported) {
      setImporting(false);
      dispatch(resetImportedComponentsAt());
      dispatch(resetImportedMappingsAt());
      onNext();
    }
  }, [importing, compsImported, mappingsImported]);

  useEffect(() => {
    if (importErrors.length) {
      setImporting(false);
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [importErrors]);

  useEffect(() => {
    if (mappingImportErrors.length) {
      dispatch(setImportErrors(mappingImportErrors));
    }
  }, [mappingImportErrors]);

  return (
    <>
      <div className="component-import-step">
        <Heading
          contentLeft={<div className="title">Import components from an Excel</div>}
          large
        />
        <div className="component-import-step__button-row">
          <Button
            onClick={() => dispatch(downloadComponentsExcel(site))}
            activity="secondary"
            design="text"
            icon={
              <div className="icon-green">
                <Icon icon="he-docexcel" />
              </div>
            }
          >
            Export component template
          </Button>
          <div className="component-import-step__info-trigger">
            <Icon icon="he-help" />
            Excel info
          </div>
          <div className="component-import-step__info-tooltip">
            <p>The downloaded excel will contain all of the components for the site.</p>
            <p>When updating the excel you can edit or add new components.</p>
          </div>
        </div>
        <div
          className="component-import-step__drop-zone"
          {...getRootProps()}
        >
          <input
            name="files"
            {...getInputProps()}
          />
          {importing ? (
            <Spinner />
          ) : (
            <div>
              <Icon
                icon="he-upload"
                size="m"
              />
              <p>{'Drag & drop components excel here, or click to select files'}</p>
            </div>
          )}
        </div>
        <Button
          onClick={() => setShowInstruction(true)}
          activity="secondary"
          design="text"
          icon={
            <div className="icon-wiz-color">
              <Icon icon="he-info" />
            </div>
          }
        >
          How to prepare a component excel
        </Button>
        {showInstruction && (
          <InstructionalModal
            fullScreenRef={fullScreenRef}
            onClose={() => setShowInstruction(false)}
            identifier="wiz-comp-import"
            title="How to prepare a component excel"
          />
        )}
      </div>
      {showErrors ? (
        <ConfirmationDialog
          onConfirm={() => {
            dispatch(setMappingsImportErrors([]));
            dispatch(setImportErrors([]));
          }}
          onCancel={() => {
            dispatch(setMappingsImportErrors([]));
            dispatch(setImportErrors([]));
          }}
          showCancel={false}
          title="Import errors"
          subtitle="The following errors occurred while attempting import:"
          body={importErrors.map((e, i) => (
            <p key={`err-${i}`}>{e}</p>
          ))}
          className="setup-wizard-errors"
        />
      ) : null}
    </>
  );
};

export default ImportStep;
